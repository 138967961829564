import "../../styles/About/AboutIntro.scss";

const AboutIntro = () => {
  return (
    <div className="aboutintro-back">
      <div className="aboutintro">
        <div className="left">
          <div className="title">
            About
            <br />
            <span className="subtitle">Synergy Nodes</span>
          </div>
          <div className="text">
          Established in 2019, Synergy Nodes is consists of a bunch of full time crypto & blockchain technology enthusiast who are committed to helping build core infrastructure for various blockchain protocols.
          </div>
        </div>
        <div className="right">
          We understand blockchain's widespread future impact on the world and
          are actively participating in its development and adoption. Bringing
          over 10 years of full time experience in crypto currency world and 5 years of full time experience in operating Validator nodes for various blockchains. 
          <br />
          <br />
          We are committed to working with the crypto community on a mutual WIN
          WIN partnership through various crypto opportunities, primarily
          focusing on operating high quality and performance servers/nodes.
          <br />
          <br />
          We launched our first validator node, Terra Classic—previously known as Terra—in May 2019. Since then, we have gained technical expertise in securely validating blockchains and delivering essential infrastructure support for various blockchain projects. We have expanded our operations and services across various mainnets and testnets.

        </div>
      </div>
    </div>
  );
};

export default AboutIntro;
