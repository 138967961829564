import AboutIntro from "./About/AboutIntro";
import Header from "./Header";
import Footer from "./Footer";
import Stats from "./Home/Stats";
import Story from "./About/Story";
import Team from "./About/Team";
import Subscribe from "./Home/Subscribe";

const About = () => {
  return (
    <>
      <Header />
      <AboutIntro />
      <Stats />
      <Team />
      <Footer />
    </>
  );
};

export default About;
