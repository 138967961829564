import "../../styles/Home/Testnet.scss";
import image1 from "../../images/test1.png";
import image2 from "../../images/test2.png";
import image3 from "../../images/test3.png";
import image4 from "../../images/test4.png";
import image5 from "../../images/test5.png";
import haqqimage from "../../images/haqq.png";
import pryzmimage from "../../images/pryzm.png";
import initiaimage from "../../images/initia.png";
import unionimage from "../../images/union.png";
import berachainimage from "../../images/berachain.png";
import sideimage from "../../images/side.png";
import sedaimage from "../../images/seda.png";
import storyimage from "../../images/story.jpg";
import junctionimage from "../../images/junction.png";
import persistenceimage from "../../images/persistence.png";
import crossfiimage from "../../images/crossfi.jpg";
import soarimage from "../../images/soarchain.png";
import axoneimage from "../../images/axone.png";
import laconicimage from "../../images/laconic.png";
import close from "../../images/modal_close.png";
import { useState } from "react";
import { Link } from "react-router-dom";
import { values } from '../../components/Chains/values';

const Testnet = () => {

  const [modalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState('');
  const [myimg, setImage] = useState('');
  const [commission, setCommission] = useState('');
  const [valoper, setValoper] = useState('');
  const [link, setLink] = useState('');

  const handleOpenElystest = () => {
    setModalOpen(true);
    setName(values['elystest'].name);
    setImage(values['elystest'].image);
    setCommission(values['elystest'].commission);
    setValoper(values['elystest'].valoper);
    setLink(values['elystest'].link);
  };

  const handleOpenAndromedatest = () => {
    setModalOpen(true);
    setName(values['andromedatest'].name);
    setImage(values['andromedatest'].image);
    setCommission(values['andromedatest'].commission);
    setValoper(values['andromedatest'].valoper);
    setLink(values['andromedatest'].link);
  };  

  const handleOpenTenettest = () => {
    setModalOpen(true);
    setName(values['tenettest'].name);
    setImage(values['tenettest'].image);
    setCommission(values['tenettest'].commission);
    setValoper(values['tenettest'].valoper);
    setLink(values['tenettest'].link);
  };  
  
  const handleOpenZetachaintest = () => {
    setModalOpen(true);
    setName(values['zetachaintest'].name);
    setImage(values['zetachaintest'].image);
    setCommission(values['zetachaintest'].commission);
    setValoper(values['zetachaintest'].valoper);
    setLink(values['zetachaintest'].link);
  };   

  const handleOpenHaqqtest = () => {
    setModalOpen(true);
    setName(values['haqqtest'].name);
    setImage(values['haqqtest'].image);
    setCommission(values['haqqtest'].commission);
    setValoper(values['haqqtest'].valoper);
    setLink(values['haqqtest'].link);
  };  
  
  const handleOpenPryzmtest = () => {
    setModalOpen(true);
    setName(values['pryzmtest'].name);
    setImage(values['pryzmtest'].image);
    setCommission(values['pryzmtest'].commission);
    setValoper(values['pryzmtest'].valoper);
    setLink(values['pryzmtest'].link);
  };

  const handleOpenUniontest = () => {
    setModalOpen(true);
    setName(values['uniontest'].name);
    setImage(values['uniontest'].image);
    setCommission(values['uniontest'].commission);
    setValoper(values['uniontest'].valoper);
    setLink(values['uniontest'].link);
  };  

  const handleOpenBerachaintest = () => {
    setModalOpen(true);
    setName(values['berachaintest'].name);
    setImage(values['berachaintest'].image);
    setCommission(values['berachaintest'].commission);
    setValoper(values['berachaintest'].valoper);
    setLink(values['berachaintest'].link);
  };  

  const handleOpenBerachainV2testnet = () => {
    setModalOpen(true);
    setName(values['berachainv2testnet'].name);
    setImage(values['berachainv2testnet'].image);
    setCommission(values['berachainv2testnet'].commission);
    setValoper(values['berachainv2testnet'].valoper);
    setLink(values['berachainv2testnet'].link);
  };   
  
  const handleOpenSidetest = () => {
    setModalOpen(true);
    setName(values['sidetest'].name);
    setImage(values['sidetest'].image);
    setCommission(values['sidetest'].commission);
    setValoper(values['sidetest'].valoper);
    setLink(values['sidetest'].link);
  };  
  
  const handleOpenSedatest = () => {
    setModalOpen(true);
    setName(values['sedatest'].name);
    setImage(values['sedatest'].image);
    setCommission(values['sedatest'].commission);
    setValoper(values['sedatest'].valoper);
    setLink(values['sedatest'].link);
  };   

  const handleOpenCrossfitest = () => {
    setModalOpen(true);
    setName(values['crossfitest'].name);
    setImage(values['crossfitest'].image);
    setCommission(values['crossfitest'].commission);
    setValoper(values['crossfitest'].valoper);
    setLink(values['crossfitest'].link);
  }; 
  
  const handleOpenPersistencetest = () => {
    setModalOpen(true);
    setName(values['persistencetest'].name);
    setImage(values['persistencetest'].image);
    setCommission(values['persistencetest'].commission);
    setValoper(values['persistencetest'].valoper);
    setLink(values['persistencetest'].link);
  };   

  const handleOpenJunctiontest = () => {
    setModalOpen(true);
    setName(values['junctiontest'].name);
    setImage(values['junctiontest'].image);
    setCommission(values['junctiontest'].commission);
    setValoper(values['junctiontest'].valoper);
    setLink(values['junctiontest'].link);
  }; 
  
  const handleOpenInitiatest = () => {
    setModalOpen(true);
    setName(values['initiatest'].name);
    setImage(values['initiatest'].image);
    setCommission(values['initiatest'].commission);
    setValoper(values['initiatest'].valoper);
    setLink(values['initiatest'].link);
  };  

  const handleOpenSoarchaintest = () => {
    setModalOpen(true);
    setName(values['soarchaintest'].name);
    setImage(values['soarchaintest'].image);
    setCommission(values['soarchaintest'].commission);
    setValoper(values['soarchaintest'].valoper);
    setLink(values['soarchaintest'].link);
  };  
  
  const handleOpenLaconictest = () => {
    setModalOpen(true);
    setName(values['laconictest'].name);
    setImage(values['laconictest'].image);
    setCommission(values['laconictest'].commission);
    setValoper(values['laconictest'].valoper);
    setLink(values['laconictest'].link);
  }; 

  const handleOpenStorytest = () => {
    setModalOpen(true);
    setName(values['storytest'].name);
    setImage(values['storytest'].image);
    setCommission(values['storytest'].commission);
    setValoper(values['storytest'].valoper);
    setLink(values['storytest'].link);
  };  
  
  const handleOpenAxonetest = () => {
    setModalOpen(true);
    setName(values['axonetest'].name);
    setImage(values['axonetest'].image);
    setCommission(values['axonetest'].commission);
    setValoper(values['axonetest'].valoper);
    setLink(values['axonetest'].link);
  };   

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="testnet-back">

    {modalOpen && (
      <>
        <div className="modalOverlay"></div>
        <div className="modal">
          <div className="modal-content">
            <div className="close_button" onClick={handleCloseModal}>
              <img src={close}></img>
            </div>
            <div className="logo">
              <img src={myimg}></img>
              <div className="name" style={{ color: "#4DA2FE" }}>
                {name}
              </div>
            </div>
            <div className="commission">Commission : {commission}</div>
            <div className="delegation">
              Delegation Address :<br />
              <span className="address">
                {valoper}
              </span>
            </div>
            <a href={link} target="_blank">
            <div className="button">
              <div className="text">Delegate Tokens</div>
            </div>
            </a>
          </div>
        </div>
      </>
    )}    

      <div className="testnet">
        <div className="title">Testnets Supported</div>
        <div className="text">
          We provide Core Infrastructure support along with some Community Tools
          & Services
        </div>
        <div className="widget">

          <div className="item">
            <div className="icon">
              <img src={storyimage}></img>
            </div>
            <div className="title">Story Testnet</div>
            <div className="text">
              Story is the World's IP Blockchain, onramping programmable IP to power the next generation of AI, DeFi, and consumer applications.
            </div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenStorytest}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/storytest">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>  

          <div className="item">
            <div className="icon">
              <img src={image1}></img>
            </div>
            <div className="title">Elys Network</div>
            <div className="text">
              Elys Network is a decentralised exchange built on Cosmos and
              CometBFT, leveraging the latest blockchain technologies to provide
              an exceptional user experience.
            </div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenElystest}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/elystest">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>

          <div className="item">
          <div className="icon">
            <img src={persistenceimage}></img>
          </div>
          <div className="title">Persistence Testnet</div>
          <div className="text">
          Persistence is an app chain for Liquid Staking powering an ecosystem of DeFi applications focused on unlocking the liquidity of staked assets.
          </div>
          <div className="buttons">
            <button className="button1" onClick={handleOpenPersistencetest}>
              <div className="button-text">Stake</div>
            </button>
            <Link className="button2" to="/service/persistencetest">
              <div className="button-text">Services</div>
            </Link>
          </div>
        </div>  


          <div className="item">
            <div className="icon">
              <img src={image2}></img>
            </div>
            <div className="title">Andromeda</div>
            <div className="text">
              Andromeda is a technology company that designs, develops, computer
              software, and online services.
            </div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenAndromedatest}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/andromedatest">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>
          <div className="item">
            <div className="icon">
              <img src={image3}></img>
            </div>
            <div className="title">Tenet</div>
            <div className="text">
              Tenet implements Diversified Proof of Stake: restake your staked
              assets to validate a smart contract network secured by omnichain
              governance.
            </div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenTenettest}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/tenettest">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>
          <div className="item">
            <div className="icon">
              <img src={haqqimage}></img>
            </div>
            <div className="title">Haqq Testnet</div>
            <div className="text">
            HAQQ is the blockchain network that issues Islamic Coin, its native cryptocurrency. HAQQ is fast, trusted and compatible with thousands of applications around the world.
            </div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenHaqqtest}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/haqqtest">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>
            
          <div className="item">
            <div className="icon">
              <img src={unionimage}></img>
            </div>
            <div className="title">Union Testnet</div>
            <div className="text">
            Union is a hyper-efficient, zero-knowledge interoperability layer that connects Appchains, Layer 1, and Layer 2 networks.
            </div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenUniontest}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/uniontest">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>
          <div className="item">
            <div className="icon">
              <img src={berachainimage}></img>
            </div>
            <div className="title">Berachain V2 Testnet</div>
            <div className="text">
            Berachain is a high-performance EVM-compatible blockchain built on Proof-of-Liquidity consensus.
            </div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenBerachainV2testnet}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/berachain-v2-testnet">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>          
          <div className="item">
            <div className="icon">
              <img src={sedaimage}></img>
            </div>
            <div className="title">Seda Testnet</div>
            <div className="text">
            SEDA is a modular data layer that sets a standard for modular data transport and queries for any data type for all networks.
            </div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenSedatest}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/sedatest">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>
          <div className="item">
            <div className="icon">
              <img src={sideimage}></img>
            </div>
            <div className="title">Side Protocol Testnet</div>
            <div className="text">
            Side Protocol is an exchange Layer for Web3 - a modular infrastructure refining asset exchange for the omnichain future.
            </div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenSidetest}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/sidetest">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div> 
          
          <div className="item">
            <div className="icon">
              <img src={junctionimage}></img>
            </div>
            <div className="title">Airchains - Junction Testnet</div>
            <div className="text">
              Airchains is a modular zk-Rollup framework platform, focused on unmatched speed and privacy. 
            </div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenJunctiontest}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/junctiontest">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>     
          
          <div className="item">
            <div className="icon">
              <img src={soarimage}></img>
            </div>
            <div className="title">Soarchain Testnet</div>
            <div className="text">
              Soarchain, a Layer 1 Network, aims to establish a decentralized ecosystem where participants, including vehicles, data providers, and developers, can interact and exchange value securely. 
            </div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenSoarchaintest}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/soarchaintest">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>    
          
          <div className="item">
            <div className="icon">
              <img src={axoneimage}></img>
            </div>
            <div className="title">Axone Testnet</div>
            <div className="text">
              Axone is a layer-1 designed for collaborative AI training, governance and monetization at scale.
            </div>
            <div className="buttons">
              <button className="button1" onClick={handleOpenAxonetest}>
                <div className="button-text">Stake</div>
              </button>
              <Link className="button2" to="/service/axonetest">
                <div className="button-text">Services</div>
              </Link>
            </div>
          </div>            

        </div>
      </div>
    </div>
  );
};

export default Testnet;
