import "../styles/Footer.scss";
import image1 from "../images/social1.png";
import image2 from "../images/social2.png";
import image3 from "../images/social3.png";
import image4 from "../images/social4.png";
import image5 from "../images/social5.png";

const Footer = () => {
  return (
    <div className="footer-back">
      <div className="footer">
        <div className="socials">
          <a href="https://discord.gg/UEppF4sBdM" target="_blank"><img src={image1}></img></a>
          <a href="https://t.me/SynergyNodes" target="_blank"><img src={image2}></img></a>
          <a href="https://twitter.com/SynergyNodes" target="_blank"><img src={image3}></img></a>
          <a href="https://github.com/SynergyNodes" target="_blank"><img src={image4}></img></a>
          <a href="https://www.youtube.com/channel/UCFOFPbgP0wLTQ007NtNl_oQ" target="_blank"><img src={image5}></img></a>
        </div>
        <div className="title">
          <span className="subtitle">email: </span>
          contact@synergynodes.com
        </div>
        <div className="title">
          <span className="subtitle">Keybase ID: </span>
          673C A766 02A5 AC88
        </div>
        <div className="copyright">
          © 2024 by Synergynodes. All Rights Reserved.
        </div>
      </div>
    </div>
  );
};

export default Footer;
